<template>
    <h1>Коммерческое предложение</h1>

    <div class="c_block">
        <div class="cbi_item">
            <div>Запрос №</div>
            <div>{{ number }}</div>
        </div>
        <div class="cbi_item">
            <div>Дата запроса</div>
            <div>{{ request_date }}</div>
        </div>
        <div class="cbi_item">
            <div>Дата отправки КП</div>
            <div>{{ send_date }}</div>
        </div>
        <div class="cbi_item">
            <div>Предложено позиций</div>
            <div>{{ positions_count }}</div>
        </div>
    </div>

    <div class="c_block">
        <h2>Организация</h2>
        <div class="half">
            <div class="cb_half">
                <div class="cbi_item">
                    <div>Наименование</div>
                    <div>{{company_name}}</div>
                </div>
                <div class="cbi_item">
                    <div>ИНН</div>
                    <div>{{ company_inn }}</div>
                </div>
                <div class="cbi_item">
                    <div>КПП</div>
                    <div>{{ company_kpp }}</div>
                </div>
                <div class="cbi_item">
                    <div>Ставка НДС</div>
                    <div>{{ company_tax }}</div>
                </div>
            </div>
            <div class="cb_half">
                <div class="cbi_item">
                    <div>Юридический адрес</div>
                    <div>{{ company_address }}</div>
                </div>
                <div class="cbi_item">
                    <div>Общая почта</div>
                    <div>{{ company_email }}</div>
                </div>
                <div class="cbi_item">
                    <div>Общий телефон</div>
                    <div>{{ company_phone }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="c_block">
        <h2>Контактное лицо</h2>
        <div class="cbi_item">
            <div>ФИО</div>
            <div>{{ contact_name }}</div>
        </div>
        <div class="cbi_item">
            <div>Должность</div>
            <div>{{ contact_position }}</div>
        </div>
        <div class="cbi_item">
            <div>Телефон</div>
            <div>{{ contact_phone }}</div>
        </div>
        <div class="cbi_item">
            <div>Почта</div>
            <div>{{ contact_email }}</div>
        </div>
    </div>

    <div class="c_block">
        <div class="pot_scroll">
            <h2>Позиции</h2>
            <div class="pos_table">
                <div class="pot_line">
                    <div>Номенклатура</div>
                    <div>Кол-во</div>
                    <div>ЕИ</div>
                    <div>Предельно допустимая цена без НДС</div>
                    <div>Цена без НДС</div>
                    <div>Цена с НДС</div>
                    <div>Сумма с НДС</div>
                    <div>Срок производства, календарных дней</div>
                    <div>Кратность</div>
                    <div>Аналог</div>
                    <div>Комментарий</div>
                </div>
                <div class="pot_line" v-for="position in positions">
                    <div>{{ position.name }}</div>
                    <div>{{ position.count }}</div>
                    <div>{{ position.unit }}</div>
                    <div>{{ position.marginal_price }} <span>₽/</span><span>{{ position.unit }}</span></div>
                    <div>{{ position.price }} ₽</div>
                    <div>{{ position.price_nds }} ₽</div>
                    <div>{{ position.sum_nds }} ₽</div>
                    <div>{{ position.days }}</div>
                    <div>{{ position.multiplicity }}</div>
                    <div>{{ position.analogue }}</div>
                    <div>{{ position.comment }}</div>
                </div>
            </div>
        </div>
        <div class="cb_warning">
            Изменения принимаются по телефону <a href="tel:+78127037289">+7 (812) 703-72-89</a> или почте <a href="mailto:info@incomposit.ru" class="h_email">info@incomposit.ru</a>
        </div>
    </div>

    <div class="c_block">
        <h2>Дополнительная информация</h2>
      <template v-for="item in payment">
      <div class="cbi_item">
            <div>Условия оплаты</div>
            <div>{{ item.conditions }}</div>
        </div>
        <div class="cbi_item">
            <div>Процент</div>
            <div>{{ item.percent }}</div>
        </div>
        <div v-if="item.days" class="cbi_item">
          <div>Дней</div>
          <div>{{ item.days }}</div>
        </div>
      </template>

        <div class="cbi_item">
            <div>Адрес склада</div>
            <div>{{ storage }}</div>
        </div>
        <div class="cbi_item">
            <div>Срок действия КП</div>
            <div>{{ term }}</div>
        </div>
        <a :href="file.link" target="_blank" class="file" v-for="file in files">
            <span>{{ file.name }}</span> <i>{{ file.size }}</i>
        </a>
    </div>
</template>

<script>
import axios from 'axios';
import login from "@/views/Login.vue";

export default {
    data: () => ({
        load: true,

        number: '',
        request_date: '',
        send_date: '',
        positions_count: '',

        company_name: '',
        company_inn: '',
        company_kpp: '',
        company_tax: '',
        company_address: '',
        company_email: '',
        company_phone: '',

        contact_name: '',
        contact_position: '',
        contact_email: '',
        contact_phone: '',

        positions: {},

        payment: {},

        days: '',
        storage: '',
        term: '',
        files: {},
    }),
    async mounted() {
        let _this = this;

        await axios.get('/local/api/history/kp/'+this.$route.params.id).then(function (response) {
            console.log(response.data);
            _this.load = false;
            if (response.data.error) {
              _this.$router.push('/404');
            }
            _this.number = response.data.number;
            _this.request_date = response.data.request_date;
            _this.send_date = response.data.send_date;
            _this.positions_count = response.data.positions_count;

            _this.company_name = response.data.company_name;
            _this.company_inn = response.data.company_inn;
            _this.company_kpp = response.data.company_kpp;
            _this.company_tax = response.data.company_tax;
            _this.company_address = response.data.company_address;
            _this.company_email = response.data.company_email;
            _this.company_phone = response.data.company_phone;

            _this.contact_name = response.data.contact_name;
            _this.contact_position = response.data.contact_position;
            _this.contact_phone = response.data.contact_phone;
            _this.contact_email = response.data.contact_email;

            _this.positions = response.data.positions;

            _this.payment = response.data.payment;

            _this.days = response.data.days;
            _this.storage = response.data.storage;
            _this.term = response.data.term;
            _this.files = response.data.files;

        }).catch(function(err) {
            console.log(err)
            _this.$router.push('/404');
        });
    },
    components: {},
    methods: {},
}
</script>

