<template>
  <div>
    <div v-html="page"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
      code: {}
    },
    data: () => ({
      page: '',
      name: '',
      id: ''
    }),
    created() {

    },
    updated() {
      if (this.id != this.$route.params.id) {
        this.$store.commit('breadcrumb_page', '');
        this.page = '';
        this.name = '';
        this.get();
      }
    },
    computed: {},
    mounted() {
      this.$store.commit('breadcrumb_page', '');
    this.get();
        //this.$store.dispatch('Loader');
    },
    beforeRouteLeave(to, from) {

        // called when the route that renders this component is about to be navigated away from.
        // As with `beforeRouteUpdate`, it has access to `this` component instance.
    },
    methods: {
      async get() {
        debugger;
        let _this = this;
        this.id = this.$route.params.id;
        let code = this.$route.params.id;
        if (this.$props.code)
          code = this.$props.code
        await axios.get('/local/api/page/'+code).then(function (response) {
          if (response.data.status == 200) {
            _this.page = response.data.item.detail;
            _this.name = response.data.item.title;
            if (code != 'index')
              _this.$store.commit('breadcrumb_page', response.data.item.title);
          }
          else
            _this.$router.push('/404');
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      }
    },
    components: {}
}
</script>

