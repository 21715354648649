<template>
    <h1 class="ab_head">Ссылка больше недействительна</h1>
    <div class="ab_text_2">Авторизуйтесь, чтобы посмотреть доступные запросы на оценку</div>
    <div class="ld_btns">
        <router-link class="btn_2 reg" to="/reg">Регистрация</router-link>
        <router-link class="btn auth" to="/auth">Вход в ЛК</router-link>
    </div>
</template>

<script>
export default {
    mounted() {
        //this.$store.dispatch('Loader');
    },
    components: {},
    methods: {},
}
</script>

