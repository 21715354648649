<template>
    <template v-if="checkword.actual==false">
      <h4>Время ссылки для создания пароля истекло или пароль уже был создан.</h4>
      <div><router-link class="btn pass_send_btn" to="/recover-password">Запросить новую ссылку</router-link></div>
    </template>
    <template v-else>
    <h1 class="ab_head">Создать пароль</h1>
    <h4>Придумайте безопасный пароль. Он должен включать в себя минимум 8 символов.</h4>
    <form class="auth_form" action="#" @submit.prevent="send">
        <div class="f_item">
            <div class="cbi_name">Пароль <span>*</span></div>
            <input class="cbi_input" :class="error.includes('password') ? 'cbi_input--error' : ''" type="password" v-model="password">
        </div>
        <div class="f_item">
            <div class="cbi_name">Повторите пароль <span>*</span></div>
            <input class="cbi_input" :class="error.includes('passwordConfirm') ? 'cbi_input--error' : ''" type="password" v-model="passwordConfirm">
        </div>
        <div class="error_text" v-if="error_text" v-html="error_text"></div>
        <button class="btn">Сохранить пароль</button>
    </form>
    </template>
</template>

<script>
import axios from "axios";

export default {
    async mounted() {
      await this.$store.dispatch('fetchCheckword', {inn: this.$route.query.inn, checkword: this.$route.query.code});
    },
  computed: {
    checkword() {
      if (this.$store.getters.checkword)
        return this.$store.getters.checkword;
      else
        return {};
    }
  },
    data: () => ({
        password: '',
        passwordConfirm: '',
        error: [],
        error_text: ""
    }),
    components: {},
    methods: {
        send: function(e) {
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];
            this.error_text = "";

            formData.append('password', _this.password);
            formData.append('password_confirm', _this.passwordConfirm);

          formData.append('code', _this.$route.query.code);
          formData.append('inn', _this.$route.query.inn);

            for (const pair of formData.entries()) {
                if (pair[1] == '') {
                    this.error.push(pair[0]);
                }
            }

            if (formData.get('password') !== formData.get('password_confirm')) {
                this.error.push('passwordConfirm');
            }

            if (this.error.length == 0) {
                console.log('ok');
              let _this = this;
              axios.post('/local/api/password/new', formData).then(response => {
                _this.error_text = '';
                let data = response.data;
                if (data.status === 'ok') {
                  _this.$store.dispatch('passwordSave', true)
                  _this.$router.push("/auth")
                }
                else {
                  //_this.error.push('login')
                  _this.error_text = data.error;
                  console.log(data)
                }
              });
                // this.success();
            }
        },
    },
}
</script>

