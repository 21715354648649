import Main from './views/Main.vue';
import MainNotAuth from './views/MainNotAuth.vue';
import Login from './views/Login.vue';
import Notfound from './views/Notfound.vue';
import TextPage from './views/TextPage.vue';
import CreatePassword from './views/CreatePassword.vue';
import PasswordSet from './views/PasswordSet.vue';
import RecoverPassword from './views/RecoverPassword.vue';
import LinkExpired from './views/LinkExpired.vue';
import RegFinished from './views/RegFinished.vue';
import Registration from './views/Registration.vue';
import Profile from './views/Profile.vue';
import ProfileEdit from './views/ProfileEdit.vue';
import Price from './views/Price.vue';
import History from './views/History.vue';
import HistoryItem from './views/HistoryItem.vue';
import AddKp from './views/AddKp.vue';

import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import store from './store';

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuth) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
   if (from.name == undefined) {
        next();
        return;
   }
   if (store.getters.isAuth) {
       next()
       return
   }
   next('/auth')
}

export default new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/auth',
            component: MainNotAuth,
            name: 'main-not-auth',
            meta: {
                layout: 'tech',
                title: 'Авторизация',
                not_auth: true,
            },
            beforeEnter: ifNotAuthenticated,
        },
        // {
        //     path: '/login',
        //     component: Login,
        //     name:'login',
        //     meta: {layout: 'tech', title: 'Авторизация'},
        //     beforeEnter: ifNotAuthenticated
        // },
        {
            path: '/create-password',
            component: CreatePassword,
            name: 'create-password',
            meta: {
                layout: 'tech',
                not_auth: true,
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/password-set',
            component: PasswordSet,
            name: 'password-set',
            meta: {
                layout: 'tech',
                not_auth: true,
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/recover-password',
            component: RecoverPassword,
            name: 'recover-password',
            meta: {
                layout: 'tech',
                not_auth: true,
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/link-expired',
            component: LinkExpired,
            name: 'link-expired',
            meta: {
                layout: 'tech',
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/reg',
            component: Registration,
            name: 'reg',
            meta: {
                layout: 'main',
                not_auth: true,
                name: 'Регистрация',
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/reg-finished',
            component: RegFinished,
            name: 'reg-finished',
            meta: {
                layout: 'tech',
                not_auth: true,
            },
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/profile',
            component: Profile,
            name: 'profile',
            meta: {
                layout: 'main',
                name: 'Профиль',
                not_auth: false,
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/profile-edit',
            component: ProfileEdit,
            name: 'profile-edit',
            meta: {
                layout: 'main',
                name: 'Редактирование профиля',
                not_auth: false,
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/price',
            component: Price,
            name: 'price',
            meta: {
                layout: 'main',
                name: 'Запросы на расценку',
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/history',
            component: History,
            name: 'history',
            meta: {
                layout: 'main',
                name: 'История КП',
                not_auth: false,
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/history/kp/:id',
            component: HistoryItem,
            name: 'history-item',
            meta: {
                layout: 'main',
                name: 'Коммерческое предложение',
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/add-kp/:id',
            component: AddKp,
            name: 'add-kp',
            meta: {
                type: 'auth',
                layout: 'main',
                name: 'Создать коммерческое предложение',
                //not_auth: false,
            },
            // beforeEnter: ifAuthenticated,
        },
        {
            path: '/add-kp-auth/:id',
            component: AddKp,
            name: 'add-kp-auth',
            meta: {
                type: 'notauth',
                layout: 'main',
                name: 'Создать коммерческое предложение',
                not_auth: 'ignore',
            },
            // beforeEnter: ifAuthenticated,
        },
        {
            path: '/',
            component: Price,
            name: 'price',
            meta: {
                layout: 'main',
                name: 'Запросы на расценку',
                not_auth: false,
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/page/:id',
            component: TextPage,
            name: 'page',
            meta: {
                layout: 'main',
            }
        },
        {
            path: '/:pathMatch(.*)*',
            component: Notfound,
            name:'notfound',
            meta: {
                layout: 'tech',
                title: 'Ошибка',
            }
        }
    ]
})