import axios from 'axios'
import Vuex from 'vuex'
import breadcrumbs from '@/store/breadcrumbs'

export default new Vuex.Store({
    state: {
        error: null,
        isAuth: false, //localStorage.getItem('user-token')?true:false,
        user: [],
        config: {
            headers:{
                token: localStorage.getItem('user-token')
            }
        },
        regFinish: false,
        passwordSave: false,
        profile: false,
        checkword: false
    },
    mutations: {
        Auth(state, data) {
            state.isAuth = data.is_auth;
            state.user = data.user;
            state.user_id = data.user_id;
        },
        regFinish(state) {
            state.regFinish = true;
        },
        passwordSave(state, data) {
            state.passwordSave = data;
        },
        profile(state, data) {
            state.profile = data['item'];
        },
        checkword(state, data) {
            state.checkword = data;
        },
        Loader(state, data) {
            state.loader = true;
        },
        unLoader(state, data) {
            state.loader = false;
        },
        setError(state, error) {
            state.error = error
        },
        clearError(state) {
            state.error = null
        },
        Logout(state, data) {
            state.isAuth = false;
            //localStorage.setItem('user-token', '');
            //state.login = data.login
        },
    },
    actions: {
        async Auth({commit, dispatch}, data) {
            commit('Auth', data);
        },
        regFinish({commit}) {
            commit('regFinish');
        },
        passwordSave({commit}, data) {
            commit('passwordSave', data);
        },
        async fetchProfile({dispatch, commit}) {
            await axios.get('/local/api/profile').then(function (response) {
                let profile = response.data; // читать тело ответа в формате JSON
                commit('profile', profile);
            }).catch(function(err) {
                console.log(err);
            });
        },
        async fetchCheckword({dispatch, commit}, data) {
            let formData = new FormData();

            formData.append('inn', data.inn);
            formData.append('checkword', data.checkword);
            await axios.post('/local/api/password/checkword', formData).then(function (response) {
                let checkword = response.data; // читать тело ответа в формате JSON
                commit('checkword', checkword);
            }).catch(function(err) {
                console.log(err);
            });
        },
        Loader({commit, dispatch}, data) {
            commit('Loader');
        },
        unLoader({commit, dispatch}, data) {
            commit('unLoader');
        },
        async Logout({commit, dispatch}, data) {
            commit('Logout');
        },
    },
    getters: {
        error: s => s.error,
        isAuth: s => s.isAuth,
        user: s => s.user,
        loader: s => s.loader,
        config: s => s.config,
        regFinish: s => s.regFinish,
        passwordSave: s => s.passwordSave,
        profile: s => s.profile,
        checkword: s => s.checkword
    },
    modules: {
        breadcrumbs
    }
})
