<template>
    <h1>Редактирование профиля</h1>

    <form action="#" class="reg_form" @submit.prevent="send">
        <div class="c_block">
            <h2>Организация</h2>
            <div class="cb_info">
                <div class="cbi_item">
                    <div>Наименование</div>
                    <div>{{ name }}</div>
                </div>
                <div class="cbi_item">
                    <div>ИНН</div>
                    <div>{{ inn }}</div>
                </div>
                <div class="cbi_item">
                    <div>КПП</div>
                    <div>{{ kpp }}</div>
                </div>
                <div class="cbi_item">
                    <div>Ставка НДС</div>
                    <div>{{ nds }}</div>
                </div>
                <div class="cb_warning">Если данные об организации не соответствуют действительности — позвоните нам или напишите на почту info@incomposit.ru</div>
            </div>
        </div>

        <div class="c_block">
            <h2>Контакты организации</h2>
            <div class="cb_row b_25">
                <div class="cb_item long">
                    <div class="cbi_name">Юридический адрес <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyAddress') ? 'cbi_input--error' : ''" type="text" v-model="companyAddress">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">Общая почта <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyEmail') ? 'cbi_input--error' : ''" type="email" placeholder="example@mail.ru" v-model="companyEmail">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">Общий телефон <span>*</span></div>
                    <input class="cbi_input phone" :class="error.includes('companyPhone') ? 'cbi_input--error' : ''" type="tel" placeholder="+7(___)___-__-__" v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" v-model="companyPhone">
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2>Адреса складов</h2>
            <div class="cb_row b_50" v-for="(store, i) in stores">
                <div class="cb_item">
                    <div class="cbi_name">Адрес склада {{ i+1 }}<span>*</span></div>
                    <input class="cbi_input" :class="error.includes('store_'+i) ? 'cbi_input--error' : ''" type="text" v-model="store.address">
                    <div class="cbi_del" v-if="i > 0" @click="deteleStoreAddress(i)"></div>
                </div>
            </div>
            <div class="cb_address_add btn_2" @click="addStoreAddress">Добавить другой адрес</div>
        </div>

        <div class="c_block">
            <h2>Список контактных лиц</h2>
            <div class="cb_rows_wrap" v-for="(contact, i) in contacts">
                <div class="cb_row">
                    <div class="cb_item long">
                        <div class="cbi_name">ФИО <span>*</span></div>
                        <input class="cbi_input" :class="error.includes('contacts_name_'+i) ? 'cbi_input--error' : ''" type="text" v-model="contact.name">
                    </div>
                    <div class="cb_item long">
                        <div class="cbi_name">Должность</div>
                        <input class="cbi_input" type="text" v-model="contact.position">
                    </div>
                </div>
                <div class="cb_row b_25_l">
                    <div class="cb_item">
                        <div class="cbi_name">Телефон <span>*</span></div>
                        <input class="cbi_input phone" :class="error.includes('contacts_phone_'+i) ? 'cbi_input--error' : ''" type="tel" placeholder="+7(___)___-__-__" v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" v-model="contact.phone">
                    </div>
                    <div class="cb_item">
                        <div class="cbi_name">Почта <span>*</span></div>
                        <input class="cbi_input" :class="error.includes('contacts_email_'+i) ? 'cbi_input--error' : ''" type="email" placeholder="example@mail.ru" v-model="contact.email">
                    </div>
                    <div class="cbw_del" v-if="i > 0" @click="deleteContact(i)"></div>
                </div>
            </div>
            <div class="cb_address_add btn_2" @click="addContact">Другое контактное лицо</div>
        </div>

        <div style="padding:0 0 15px;" class="error_text" v-if="error_text" v-html="error_text"></div>

        <button class="btn save_btn">Сохранить изменения</button>
    </form>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';
import axios from "axios";

export default {
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    data: () => ({
        error: [],
        error_text: '',
        companyAddress: '',
        companyEmail: '',
        companyPhone: '',
      inn: '',
      kpp: '',
      nds: '',
      name: '',
        stores: [
            {
                address: '',
            }
        ],
        contacts: [
            {
                name: '',
                position: '',
                phone: '',
                email: '',
            }
        ]
    }),
    async mounted() {
        await this.$store.dispatch('fetchProfile');
        let profile = this.$store.getters.profile;
        if (profile) {

          this.companyAddress = profile.address;
          this.companyEmail = profile.email;
          this.companyPhone = profile.personal_phone;
          this.stores = profile.sklad;
          this.contacts = profile.contacts;

          this.inn = profile.inn;
          this.kpp = profile.kpp;
          this.nds = profile.nds;
          this.name = profile.company;
        }
    },
    components: {},
    methods: {
        deteleStoreAddress: function(i) {
            this.stores.splice(i, 1);
        },
        addStoreAddress: function() {
            this.stores.push({address: ''});
        },
        deleteContact: function(i) {
            this.contacts.splice(i, 1);
        },
        addContact: function() {
            this.contacts.push({
                name: '',
                position: '',
                phone: '',
                email: '',
            });
        },
        send: function(e) {
            let form = e.target,
                formData = new FormData(),
                _this = this;

            this.error = [];
            //debugger;
            formData.append('companyAddress', _this.companyAddress);
            formData.append('companyEmail', _this.companyEmail);
            formData.append('companyPhone', _this.companyPhone);
            formData.append('stores', JSON.stringify(_this.stores));
            formData.append('contacts', JSON.stringify(_this.contacts));

            if (formData.get('companyAddress') == '') {
                this.error.push('companyAddress');
            }

            if (formData.get('companyEmail') == '') {
                this.error.push('companyEmail');
            }

            if (formData.get('companyPhone') == '' || formData.get('companyPhone').length < 16) {
                this.error.push('companyPhone');
            }

            let storesArray = JSON.parse(formData.get('stores'));
            for (let i in storesArray) {
                if (storesArray[i].address == '') {
                    this.error.push('store_'+i);
                }
            }

            let contactsArray = JSON.parse(formData.get('contacts'));
            for (let i in contactsArray) {
                if (contactsArray[i].name == '') {
                    this.error.push('contacts_name_'+i);
                }

                if (contactsArray[i].phone == '' || contactsArray[i].phone.length < 16) {
                    this.error.push('contacts_phone_'+i);
                }

                if (contactsArray[i].email == '') {
                    this.error.push('contacts_email_'+i);
                }
            }

            if (this.error.length == 0) {
                console.log('ok');
              let _this = this;
              axios.post('/local/api/profile/save', formData).then(response => {
                _this.error_text = '';
                let data = response.data;
                if (data.status === 'ok') {
                  /*_this.$store.dispatch('Auth', {
                    login: data.login,
                    token: data.token,
                    user: data.arUser,
                    uuid: data.uuid
                  })
                  _this.axios.defaults.params = {Authorization: data.token}*/
                  //_this.$store.dispatch('Auth', data)
                  _this.$router.push("/profile");
                }
                else {
                  //_this.error.push('login')
                  debugger;
                  _this.error_text = data.error;
                  console.log(data)
                }
              });
                // this.success();
            }
        },
        maskResetPhone: function(e) {
            if (e.target.value.length < 16)
                e.target.value = '';
        },
    },
}
</script>

