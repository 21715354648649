<template>
    <header>
        <div class="header">
            <a class="burger" :class="menuOpen ? 'opened' : ''" @click="menuOpen = !menuOpen"><span></span></a>
            <div class="logo"><img src="@/images/logo.svg" alt=""></div>

            <slide-up-down :active="menuOpen == true">
                <div class="h_btns h_btns--mobile">
                    <router-link class="btn_2 reg" to="/reg" v-if="!authenticated" @click="menuOpen = !menuOpen">Регистрация</router-link>
                    <router-link class="btn auth" to="/auth" v-if="!authenticated" @click="menuOpen = !menuOpen">Вход в ЛК</router-link>

                    <ul class="hb_menu" v-if="authenticated">
                        <li><router-link to="/" @click="menuOpen = !menuOpen">Запросы на расценку</router-link></li>
                        <li><router-link to="/history" @click="menuOpen = !menuOpen">История КП</router-link></li>
                        <li><router-link to="/profile" @click="menuOpen = !menuOpen">Профиль</router-link></li>
                    </ul>
                    <a class="btn_2 auth" @click="logout" v-if="authenticated">Выход из ЛК</a>
                </div>
            </slide-up-down>

            <div class="h_btns h_btns--desktop">
                <router-link class="btn_2 reg" to="/reg" v-if="!authenticated">Регистрация</router-link>
                <router-link class="btn auth" to="/auth" v-if="!authenticated">Вход в ЛК</router-link>

                <ul class="hb_menu" v-if="authenticated">
                    <li><router-link to="/">Запросы на расценку</router-link></li>
                    <li><router-link to="/history">История КП</router-link></li>
                    <li><router-link to="/profile">Профиль</router-link></li>
                </ul>
                <a class="btn_2 auth" @click="logout" v-if="authenticated">Выход из ЛК</a>
            </div>
        </div>
    </header>
  <div class="main">
    <div class="content">
        <div class="auth_block">
            <div class="ab_left">
                <div class="ab_content">
                    <router-view/>
                </div>
            </div>
            <div class="ab_right"></div>
        </div>
    </div>
  </div>
    <footer>
        <div class="footer">
            <div class="footer_top">
                <div class="fti_text">Нужна помощь?</div>
                <a class="fti_tel" href="tel:+78127037289">+7 (812) 703-72-89</a>
                <a class="fti_email" href="mailto:info@incomposit.ru">info@incomposit.ru</a>
            </div>
            <div class="footer_bot">
                <div class="copy">© Онлайн коммуникации 2023</div>
                <router-link to="/page/policy">Политика конфиденциальности</router-link>
                <router-link to="/page/rule">Правила сайта</router-link>
                <a href="javascript:;" @click="openLinkInNewTab('https://profitkit.ru/')" class="dev">Разработано в <img src="@/images/dev.svg" alt=""></a>
            </div>
        </div>
    </footer>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';
import axios from "axios";

export default {
    name: 'tech-layout',
    props: [
        'title'
    ],
    data: () => ({
        menuOpen: false,
    }),
  methods: {
        logout() {
            let _this = this;
            axios.get('/local/api/logout').then(function (response) {
                debugger;
                _this.$store.dispatch('Auth', {
                    login: '',
                    token: '',
                    user: '',
                    uuid: '',
                    is_auth: false
                })
                _this.$router.push('/auth');
            }).catch(function(err) {
                _this.$router.push('/auth');
            });
        },
        prevPage() {
            this.$router.go(-1)
        },
        openLinkInNewTab(url) {
            window.open(url, '_blank');
        },
    },
    computed: {
        authenticated() {
          return this.$store.state.isAuth
        }
    },
    components: {
        SlideUpDown,
    }
}
</script>