export default {
    state: {
        breadcrumbs: false,
        breadcrumb_page: false,
    },
    mutations: {
        breadcrumbs(state, breadcrumbs) {
            debugger;
            state.breadcrumbs = breadcrumbs;
        },
        breadcrumb_page(state, breadcrumb_page) {
            state.breadcrumb_page = breadcrumb_page;
        }
    },
    getters: {
        breadcrumbs: s => s.breadcrumbs,
        breadcrumb_page: s => s.breadcrumb_page
    },
    actions: {

    }
}
