<template>
    <h1 class="ab_head">Спасибо за регистрацию!</h1>
    <h4>После модерации на указанную Общую почту мы вышлем ссылку на создание пароля. Это может занять 3–5 дней.</h4>
</template>

<script>
export default {
    mounted() {
        //this.$store.dispatch('Loader');
    },
    created() {
      if (this.$store.getters.regFinish === false) {
        if (this.$store.getters.isAuth)
          this.$router.push("/profile");
        else
          this.$router.push("/reg");
      }
    },
  components: {},
    methods: {},
}
</script>

