<template>
  <template v-if="password_save">
    <h1 class="ab_head">Пароль успешно установлен</h1>
    <h4>Войдите в аккаунт</h4>
  </template>
    <h1 v-else class="ab_head">Вход в личный кабинет</h1>
    <form class="auth_form" action="#" @submit.prevent="send">
        <div class="f_item">
            <div class="cbi_name">ИНН <span>*</span></div>
            <input class="cbi_input" :class="error.includes('login') ? 'cbi_input--error' : ''" type="text" placeholder="_ _ _ _ _ _ _ _ _ _" v-mask="'############'" @blur="maskResetInn" v-model="login" onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
        </div>
        <div class="f_item">
            <div class="cbi_name">Пароль <span>*</span></div>
            <input class="cbi_input" :class="error.includes('password') ? 'cbi_input--error' : ''" :type="passwordType" v-model="password">
          <span :class="passwordType=='password'?'password_show':'password_hide'" @click="showPassword">
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z" stroke="#1C274C" stroke-width="1.5"/>
<path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#1C274C" stroke-width="1.5"/>
</svg>
          </span>
        </div>
        <router-link to="/recover-password" class="f_pass">Забыли пароль?</router-link> <br>
        <div class="error_text" v-if="error_text" v-html="error_text"></div>
        <button class="f_enter btn">Войти</button>
    </form>
</template>

<script>
import axios from "axios";
import {mask as maskDirective } from 'vue-the-mask';

export default {
    props: [],
  directives: {
    mask: function (e, b) {
      if (!b.value) {
        return;
      }

      maskDirective(e, b);
    }
  },
    data: () => ({
        login: '',
        password: '',
        error: [],
      error_text: "",
      passwordType: 'password'
    }),
    created() {

    },
    computed: {
      password_save() {
        let save = this.$store.getters.passwordSave;
        if (save)
          this.$store.dispatch('passwordSave', false);
        return save;
      }
    },
    mounted() {

        //this.$store.dispatch('Loader');
    },
    beforeRouteLeave(to, from) {

        // called when the route that renders this component is about to be navigated away from.
        // As with `beforeRouteUpdate`, it has access to `this` component instance.
    },
    components: {},
    methods: {
        send: function(e) {
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];
            this.error_text = "";

            formData.append('login', _this.login);
            formData.append('password', _this.password);

            for (const pair of formData.entries()) {
                if (pair[1] == '') {
                    this.error.push(pair[0]);
                }
            }

            if (this.error.length == 0) {
                console.log('ok');
                let _this = this;
                axios.post('/local/api/login', formData).then(response => {
                _this.error_text = '';
                let data = response.data;
                if (data.is_auth === true) {
                  /*_this.$store.dispatch('Auth', {
                    login: data.login,
                    token: data.token,
                    user: data.arUser,
                    uuid: data.uuid
                  })
                  _this.axios.defaults.params = {Authorization: data.token}*/
                  _this.$store.dispatch('Auth', data)
                  _this.$router.push("/profile");
                }
                else {
                  //_this.error.push('login')
                  _this.error_text = data.error.MESSAGE;
                  console.log(data)
                }
              });
                // this.success();
            }
        },
      showPassword() {
        if (this.passwordType == 'password')
          this.passwordType = 'text';
        else
          this.passwordType = 'password';
      },
      maskResetInn: function(e) {
        if (e.target.value.length != 10 && e.target.value.length != 12)
          e.target.value = '';
      },
    },
}
</script>

