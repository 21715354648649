<template>
    <h1>Запросы на расценку</h1>

    <div class="cb_warning">Мы ожидаем подачи ценовых предложений в течение 30 дней с даты создания запроса. По истечении этого срока от вас придет автоматический отказ от подачи КП</div>

    <div class="p_table">
        <div class="pt_sort_btn btn_3" @click="opensort">Параметры сортировки</div>
        <div class="p_block">
            <div class="pt_line_top">
                <span :class="{'active': sortby=='number', 'revert':order.number==1}" @click="sort('number')">Номер закупки</span>
                <span :class="{'active': sortby=='position', 'revert':order.position==1}" @click="sort('position')">Запрошено позиций</span>
                <span :class="{'active': sortby=='date', 'revert':order.date==1}" @click="sort('date')">Дата создания запроса</span>
                <span :class="{'active': sortby=='price', 'revert':order.price==1}" @click="sort('price')">Сумма предельно допустимых цен</span>
                <div class="pt_close" @click="closesort"></div>
            </div>
            <template v-for="item in items">
            <router-link :to="'/add-kp/'+item.id" class="pt_line">
                <span>{{ item.number }}</span>
                <span>{{ item.position }}</span>
                <span>{{ item.date }}</span>
                <span>{{ item.price }} ₽</span>
            </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import jQuery from 'jquery';

export default {
  data: () => ({
    items: [],
    order: {
      number: 1,
      position: 1,
      date: 1,
      price: 1
    },
    sortby: ''
  }),
    mounted() {
        //this.$store.dispatch('Loader');
      this.get();
    },
    components: {},
    methods: {
      async get() {
        let _this = this;
        await axios.get('/local/api/requestprice').then(function (response) {
          _this.items = response.data.items;
        }).catch(function(err) {

        });
      },
      sort(field) {
        this.items = this.items.sort(function (a, b) {
          if (a[field] > b[field]) {
            return 1;
          }
          if (a[field] < b[field]) {
            return -1;
          }
          // a должно быть равным b
          return 0;
        });

        if (this.order[field]==1) {
          this.items = this.items.reverse();
          this.order[field]=0;
        }
        else {
          this.order[field] = 1;
        }
        this.sortby = field;
      },
      opensort() {
        jQuery(".pt_line_top").slideDown();
      },
      closesort() {
        jQuery('.pt_line_top').slideUp(200);
      }
    },
}
</script>

