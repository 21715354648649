<template>
    <h1 class="ab_head">Пароль успешно установлен</h1>
    <h4>Войдите в аккаунт</h4>
    <form class="auth_form" action="#" @submit.prevent="send">
        <div class="f_item">
            <div class="cbi_name">ИНН <span>*</span></div>
            <input class="cbi_input" :class="error.includes('login') ? 'cbi_input--error' : ''" type="text" v-model="login" onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
        </div>
        <div class="f_item">
            <div class="cbi_name">Пароль <span>*</span></div>
            <input class="cbi_input" :class="error.includes('password') ? 'cbi_input--error' : ''" type="password" v-model="password">
        </div>
        <router-link to="/recover-password" class="f_pass">Забыли пароль?</router-link> <br>
        <button class="f_enter btn">Войти</button>
    </form>
</template>

<script>
export default {
    mounted() {
        //this.$store.dispatch('Loader');
    },
    data: () => ({
        login: '',
        password: '',
        error: [],
    }),
    components: {},
    methods: {
        send: function(e) {
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];

            formData.append('login', _this.login);
            formData.append('password', _this.password);

            for (const pair of formData.entries()) {
                if (pair[1] == '') {
                    this.error.push(pair[0]);
                }
            }

            if (this.error.length == 0) {
                console.log('ok');
                console.log(formData);
                // this.success();
            }
        },
    },
}
</script>

