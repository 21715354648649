<template>
    <h1>Профиль</h1>

    <form action="#" class="reg_form">

        <div class="c_block">
            <h2>Организация</h2>
            <div class="cb_info">
                <div class="cbi_item">
                    <div>Наименование</div>
                    <div>{{ profile.company }}</div>
                </div>
                <div class="cbi_item">
                    <div>ИНН</div>
                    <div>{{ profile.inn }}</div>
                </div>
                <div class="cbi_item">
                    <div>КПП</div>
                    <div>{{ profile.kpp }}</div>
                </div>
                <div class="cbi_item">
                    <div>Ставка НДС</div>
                    <div>{{ profile.nds }}</div>
                </div>
                <div class="cb_warning">Если данные об организации не соответствуют действительности — позвоните нам или напишите на почту info@incomposit.ru</div>
            </div>
        </div>

        <router-link class="btn_3 edit_btn" to="/profile-edit">Редактировать контакты</router-link>

        <div class="c_block">
            <h2>Контакты организации</h2>
            <div class="cb_info">
                <div class="cbi_item">
                    <div>Юридический адрес</div>
                    <div>{{profile.address}}</div>
                </div>
                <div class="cbi_item">
                    <div>Общая почта</div>
                    <div>{{profile.email}}</div>
                </div>
                <div class="cbi_item">
                    <div>Общий телефон</div>
                    <div>{{profile.personal_phone}}</div>
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2>Адреса складов</h2>
            <div class="cb_info">
                <div v-for="(item, index) in profile.sklad" class="cbi_item">
                    <div>Адрес склада {{index+1}}</div>
                    <div>{{ item.address }}</div>
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2>Список контактных лиц</h2>
            <div class="cbi_contacts">
                <div class="cb_info">
                    <div v-for="item in profile.contacts" class="cbi_item">
                        <div class="cbi_col">
                            <div>{{ item.name }}</div>
                            <div>{{ item.position }}</div>
                        </div>
                        <div class="cbi_col">
                            <div><a :href="'tel:+'+item.phone_clear">{{ item.phone }}</a></div>
                            <div><a :href="'mailto:'+item.email">{{ item.email }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data: () => ({

    }),
    async mounted() {
        //this.$store.dispatch('Loader');
        await this.$store.dispatch('fetchProfile');
    },
    computed: {
        profile() {
            if (this.$store.getters.profile)
                return this.$store.getters.profile;
            else
                return {};
        }
    },
    components: {},
    methods: {},
}
</script>

