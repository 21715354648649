<template>
  <template v-if="success_text==''">
    <h1 class="ab_head">Восстановление пароля</h1>
    <h4>Введите Email и мы направим вам на e-mail ссылку для восстановления пароля</h4>
    <form class="auth_form" action="#" @submit.prevent="send">
      <div v-if="success_text" v-html="success_text"></div>
      <div class="f_item">
        <div class="cbi_name">Email <span>*</span></div>
        <input typeof="email" class="cbi_input" :class="error_text || error.includes('login') ? 'cbi_input--error' : ''" type="text" v-model="login">
      </div>
      <div class="error_text" v-if="error_text" v-html="error_text"></div>
      <button class="btn pass_send_btn">Отправить</button>
    </form>
  </template>
  <template v-else>
    <h1 class="ab_head">Запрос принят!</h1>
    <h4>Скоро мы вышлем вам ссылку на восстановление пароля</h4>
  </template>

</template>

<script>
import axios from "axios";

export default {
    mounted() {
        //this.$store.dispatch('Loader');
    },
    data: () => ({
        login: '',
        error: [],
      error_text: "",
      success_text: ""
    }),
    components: {},
    methods: {
        send: function(e) {
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];
            this.error_text = "";
            this.success_text = "";

            formData.append('login', _this.login);

            for (const pair of formData.entries()) {
                if (pair[1] == '') {
                    this.error.push(pair[0]);
                }
            }

            if (this.error.length == 0) {
                console.log('ok');
              let _this = this;
              axios.post('/local/api/password/request', formData).then(response => {
                _this.error_text = '';
                let data = response.data;
                if (data.status === 'ok') {

                  _this.success_text = data.text;
                }
                else {
                  //_this.error.push('login')
                  _this.error_text = data.error;
                  console.log(data)
                }
              });
                // this.success();
            }
        },
    },
}
</script>

