<template>
    <h1>Регистрация</h1>

    <form action="#" class="reg_form" @submit.prevent="send">
        <div class="c_block">
            <h2 class="scroll_org">Организация</h2>
            <div class="cb_row">
                <div class="cb_item long">
                    <div class="cbi_name">Наименование <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyName') ? 'cbi_input--error' : ''" type="text" v-model="companyName">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">ИНН <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyInn') ? 'cbi_input--error' : ''" type="text" placeholder="_ _ _ _ _ _ _ _ _ _" v-mask="'############'" @blur="maskResetInn" v-model="companyInn">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">КПП</div>
                    <input class="cbi_input kpp" :class="error.includes('companyKpp') ? 'cbi_input--error' : ''" type="text" placeholder="_ _ _ _ _ _ _ _ _" v-mask="'#########'" @blur="maskResetKpp" v-model="companyKpp">
                </div>
                <div class="cb_item" :class="error.includes('companyTax') ? 'select--error' : ''">
                    <div class="cbi_name">Ставка НДС <span>*</span></div>
                    <select class="selectBlock" v-model="companyTax">
                        <option placeholder value="">Выберите</option>
                        <option value="0%">Без НДС</option>
                        <option value="10%">10%</option>
                        <option value="20%">20%</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2 class="scroll_contacts">Контакты организации</h2>
            <div class="cb_row b_25">
                <div class="cb_item long">
                    <div class="cbi_name">Юридический адрес <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyAddress') ? 'cbi_input--error' : ''" type="text" v-model="companyAddress">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">Общая почта <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('companyEmail') ? 'cbi_input--error' : ''" type="email" placeholder="example@mail.ru" v-model="companyEmail">
                </div>
                <div class="cb_item">
                    <div class="cbi_name">Общий телефон <span>*</span></div>
                    <input class="cbi_input phone" :class="error.includes('companyPhone') ? 'cbi_input--error' : ''" type="tel" placeholder="+7(___)___-__-__" v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" v-model="companyPhone">
                </div>
            </div>
            <div class="cb_warning">Проверьте, что у вас есть доступ к почте. На эту почту будет направлена ссылка на создание пароля.</div>
        </div>

        <div class="c_block">
            <h2 class="scroll_store">Адреса складов</h2>
            <div class="cb_row b_50" v-for="(store, i) in stores">
                <div class="cb_item">
                    <div class="cbi_name">Адрес склада {{ i+1 }}<span>*</span></div>
                    <input class="cbi_input" :class="error.includes('store_'+i) ? 'cbi_input--error' : ''" type="text" v-model="store.address">
                    <div class="cbi_del" v-if="i > 0" @click="deteleStoreAddress(i)"></div>
                </div>
            </div>
            <div class="cb_address_add btn_2" @click="addStoreAddress">Добавить другой адрес</div>
        </div>

        <div class="c_block">
            <h2 class="scroll_user">Список контактных лиц</h2>
            <div class="cb_rows_wrap" v-for="(contact, i) in contacts">
                <div class="cb_row">
                    <div class="cb_item long">
                        <div class="cbi_name">ФИО <span>*</span></div>
                        <input class="cbi_input" :class="error.includes('contacts_name_'+i) ? 'cbi_input--error' : ''" type="text" v-model="contact.name">
                    </div>
                    <div class="cb_item long">
                        <div class="cbi_name">Должность</div>
                        <input class="cbi_input" type="text" v-model="contact.position">
                    </div>
                </div>
                <div class="cb_row b_25_l">
                    <div class="cb_item">
                        <div class="cbi_name">Телефон <span>*</span></div>
                        <input class="cbi_input phone" :class="error.includes('contacts_phone_'+i) ? 'cbi_input--error' : ''" type="tel" placeholder="+7(___)___-__-__" v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" v-model="contact.phone">
                    </div>
                    <div class="cb_item">
                        <div class="cbi_name">Почта <span>*</span></div>
                        <input class="cbi_input" :class="error.includes('contacts_email_'+i) ? 'cbi_input--error' : ''" type="email" placeholder="example@mail.ru" v-model="contact.email">
                    </div>
                    <div class="cbw_del" v-if="i > 0" @click="deleteContact(i)"></div>
                </div>
            </div>
            <div class="cb_address_add btn_2" @click="addContact">Другое контактное лицо</div>
        </div>

        <label class="conf_check niceCheck scroll_agree" :class="error.includes('agreement') ? 'conf_check--error' : ''">
            <input type="checkbox" name="ch1" v-model="agreement">
            <span>Я соглашаюсь с <router-link to="/page/policy">Политикой конфиденциальности</router-link> и <router-link to="/page/rule">Правилами сайта</router-link></span>
        </label>

      <div class="error_text" v-if="error_text" v-html="error_text"></div>

        <button class="btn reg_btn">Зарегистрироваться</button>
    </form>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';
import Choices from 'choices.js';
import axios from "axios";

export default {
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    data: () => ({
        error: [],
        companyName: '',
        companyInn: '',
        companyKpp: '',
        companyTax: '',
        companyAddress: '',
        companyEmail: '',
        companyPhone: '',
        stores: [
            {
                address: '',
            }
        ],
        contacts: [
            {
                name: '',
                position: '',
                phone: '',
                email: '',
            }
        ],
        agreement: false,
      error_text: ""
    }),
    async mounted() {
        this.createSelects();
    },
    components: {},
    methods: {
        createSelects: function() {
            let selectList = document.querySelectorAll('.selectBlock');

            selectList.forEach(function (item) {
                let choices = new Choices(item, {
                    placeholder: true,
                    position: 'bottom',
                });
            });
        },
        deteleStoreAddress: function(i) {
            this.stores.splice(i, 1);
        },
        addStoreAddress: function() {
            this.stores.push({address: ''});
        },
        deleteContact: function(i) {
            this.contacts.splice(i, 1);
        },
        addContact: function() {
            this.contacts.push({
                name: '',
                position: '',
                phone: '',
                email: '',
            });
        },
        send: function(e) {
            let form = e.target,
                formData = new FormData(),
                _this = this,
                top = 0;

            this.error = [];
            this.error_text = "";

            formData.append('companyName', _this.companyName);
            formData.append('companyInn', _this.companyInn);
            formData.append('companyKpp', _this.companyKpp);
            formData.append('companyTax', _this.companyTax);
            formData.append('companyAddress', _this.companyAddress);
            formData.append('companyEmail', _this.companyEmail);
            formData.append('companyPhone', _this.companyPhone);
            formData.append('stores', JSON.stringify(_this.stores));
            formData.append('contacts', JSON.stringify(_this.contacts));




          if (!_this.agreement) {
            this.error.push('agreement');
            top = document.querySelector(".scroll_agree").offsetTop;
          }

            let contactsArray = JSON.parse(formData.get('contacts'));
            for (let i in contactsArray) {
                if (contactsArray[i].name == '') {
                    this.error.push('contacts_name_'+i);
                  top = document.querySelector(".scroll_user").offsetTop;
                }

                if (contactsArray[i].phone == '' || contactsArray[i].phone.length < 16) {
                    this.error.push('contacts_phone_'+i);
                  top = document.querySelector(".scroll_user").offsetTop;
                }

                if (contactsArray[i].email == '') {
                    this.error.push('contacts_email_'+i);
                  top = document.querySelector(".scroll_user").offsetTop;
                }
            }



          let storesArray = JSON.parse(formData.get('stores'));
          for (let i in storesArray) {
            if (storesArray[i].address == '') {
              this.error.push('store_'+i);
              top = document.querySelector(".scroll_store").offsetTop;
            }
          }

          if (formData.get('companyAddress') == '') {
            this.error.push('companyAddress');
            top = document.querySelector(".scroll_contacts").offsetTop;
          }

          if (formData.get('companyEmail') == '') {
            this.error.push('companyEmail');
            top = document.querySelector(".scroll_contacts").offsetTop;
          }

          if (formData.get('companyPhone') == '' || formData.get('companyPhone').length < 16) {
            this.error.push('companyPhone');
            top = document.querySelector(".scroll_contacts").offsetTop;
          }

          if (formData.get('companyName') == '') {
            this.error.push('companyName');
            top = document.querySelector(".scroll_org").offsetTop;
          }

          if (formData.get('companyInn') == '') {
            this.error.push('companyInn');
            top = document.querySelector(".scroll_org").offsetTop;
          }

          if (formData.get('companyKpp') && formData.get('companyKpp').length < 9) {
            this.error.push('companyKpp');
            top = document.querySelector(".scroll_org").offsetTop;
          }

          if (formData.get('companyTax') == '') {
            this.error.push('companyTax');
            top = document.querySelector(".scroll_org").offsetTop;
          }


          if (this.error.length == 0) {
                console.log('ok');
              let _this = this;
              axios.post('/local/api/registration', formData).then(response => {
                _this.error_text = '';
                let data = response.data;
                if (data.user_id) {
                  /*_this.$store.dispatch('Auth', {
                    login: data.login,
                    token: data.token,
                    user: data.arUser,
                    uuid: data.uuid
                  })
                  _this.axios.defaults.params = {Authorization: data.token}*/
                  _this.$store.dispatch('regFinish');
                  _this.$router.push("/reg-finished");
                }
                else {
                  //_this.error.push('login')
                  _this.error_text = data.error.MESSAGE;
                  console.log(data)
                }
              });
                // this.success();
            }
            else
              window.scrollTo(0, top);
        },
        maskResetPhone: function(e) {
            if (e.target.value.length < 16)
                e.target.value = '';
        },
        maskResetKpp: function(e) {
            if (e.target.value.length < 9)
                e.target.value = '';
        },
        maskResetInn: function(e) {
            if (e.target.value.length != 10 && e.target.value.length != 12)
                e.target.value = '';
        },
    },
}
</script>

