<template>
  <ul class="listing" v-if="pagination && pagination.num_page>1">
    <li class="pagination-item" @click="gotopage(1)"><a :class="pagination.page==1?'current':''">1</a></li>
    <li v-if="pagination.page>3" class="pagination-dots">...</li>
    <template v-if="pagination.num_page > 2">
      <template v-for="i in pagination.pages">
        <li class="pagination-item" @click="gotopage(i)"><a :class="pagination.page==i?'current':''">{{i}}</a></li>
      </template>

    </template>
    <li v-if="pagination.page+2<pagination.num_page" class="pagination-dots">...</li>
    <li class="pagination-item" @click="gotopage(pagination.num_page)"><a :class="pagination.page==pagination.num_page?'current':''">{{ pagination.num_page }}</a></li>
  </ul>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';

export default {
  props: {
    pagination: {}
  },
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    mounted() {

    },
    components: {},
    methods: {
        gotopage(page) {
          if (page != this.$props.pagination.page)
            this.$emit('gotopage', page);
        }
    },
    emits: ['gotopage'],
}
</script>
