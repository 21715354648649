<template>

    <template v-if="request_error">
      <div class="step" >
        <div class="c_block">
      {{request_error}}
        </div></div>
    </template>
    <template v-else>
      <h1>Создать коммерческое предложение</h1>

    <div class="step" v-if="step1">
        <div class="c_block">
            <div class="cbi_item">
                <div>Запрос №</div>
                <div>{{ request.number }}</div>
            </div>
            <div class="cbi_item">
                <div>Дата запроса</div>
                <div>{{request.date}}</div>
            </div>
        </div>

        <div class="stages">
            <div class="stage">
                <span>1</span>
                <div>Выбор позиций</div>
            </div>
            <div class="stage inactive">
                <span>2</span>
                <div>Подача ценового предложения</div>
            </div>
        </div>

      <div class="c_block">
        <h2 class="no_bd">Выбор позиций</h2>
        <div class="cb_warning fix_1 scroll_positions" ref="positionsAlert">Выберите позиции, для которых у вас есть предложение ИЛИ направьте отказ от подачи КП</div>
        <div class="positions_choose">
          <div class="pc_all">
            <label class="niceCheck">
              <input type="checkbox" @change="toggleAllCheckboxes">
              <span>Выбрать все позиции</span>
            </label>
          </div>
          <div class="pc_scroll">
            <div class="pc_table">
              <div class="pc_line_top">
                <div>Номенклатура</div>
                <div>Кол-во</div>
                <div>ЕИ</div>
                <div>Предельно допустимая цена без НДС</div>
              </div>
              <div class="pc_line" v-for="item in positions">
                <div>
                  <label class="niceCheck">
                    <input type="checkbox" v-model="item.active" @change="removePositionsError">
                    <span>{{ item.name }}</span>
                  </label>
                </div>

                <div>{{ item.quantity }}</div>
                <div>{{ item.unit }}</div>
                <div>{{ item.maxPrice }} <span>₽/</span><span>{{ item.unit }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="c_block" v-if="is_auth">
            <h2>Организация</h2>
            <div class="half">
                <div class="cb_half">
                    <div class="cbi_item">
                        <div>Наименование</div>
                        <div>{{profile.company}}</div>
                    </div>
                    <div class="cbi_item">
                        <div>ИНН</div>
                        <div>{{ profile.inn }}</div>
                    </div>
                    <div class="cbi_item">
                        <div>КПП</div>
                        <div>{{ profile.kpp }}</div>
                    </div>
                    <div class="cbi_item">
                        <div>Ставка НДС</div>
                        <div>{{ profile.nds }}</div>
                    </div>
                </div>
                <div class="cb_half">
                    <div class="cbi_item">
                        <div>Юридический адрес</div>
                        <div>{{ profile.address }}</div>
                    </div>
                    <div class="cbi_item">
                        <div>Общая почта</div>
                        <div>{{ profile.email }}</div>
                    </div>
                    <div class="cbi_item">
                        <div>Общий телефон</div>
                        <div>{{ profile.personal_phone }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c_block" v-if="is_auth">
            <h2 class="scroll_contacts">Контактное лицо</h2>
            <div class="cb_row b_50">
                <div class="cb_item" ref="taxSelect" v-if="profile.contacts">
                    <div class="cbi_name">Контактное лицо <span>*</span></div>
                    <select class="selectBlock" v-model="contact">
                        <option placeholder value="">Выберите контактное лицо</option>
                        <option v-for="item in profile.contacts" :value="item.ID">{{ item.name }}</option>

                    </select>
                </div>
            </div>
        </div>

      <template v-if="!is_auth">
        <div class="c_block">
          <h2 class="scroll_org">Организация</h2>
          <div class="cb_row">
            <div class="cb_item long">
              <div class="cbi_name">Наименование <span>*</span></div>
              <input v-model="name" class="cbi_input" type="text" required="required" :class="error.includes('companyName') ? 'cbi_input--error' : ''">
            </div>
            <div class="cb_item">
              <div class="cbi_name">ИНН <span>*</span></div>
              <input v-model="inn" :class="error.includes('companyInn') ? 'cbi_input--error' : ''" placeholder="_ _ _ _ _ _ _ _ _ _" v-mask="'############'" @blur="maskResetInn" class="cbi_input" type="text" required="required" onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
            </div>
            <div class="cb_item">
              <div class="cbi_name">КПП</div>
              <input v-model="kpp" :class="error.includes('companyKpp') ? 'cbi_input--error' : ''" v-mask="'#########'" @blur="maskResetKpp" class="cbi_input kpp" type="text" placeholder="_ _ _ _ _ _ _ _ _" >
            </div>
            <div class="cb_item" :class="error.includes('companyTax') ? 'select--error' : ''">
              <div class="cbi_name">Ставка НДС <span>*</span></div>
              <select v-model="nds" class="selectBlock">
                <option placeholder class="pholder" value="0">Выберите</option>
                <option>Без НДС</option>
                <option>10%</option>
                <option>20%</option>
              </select>
            </div>
          </div>
        </div>

        <div class="c_block">
          <h2 class="scroll_contacts">Контакты организации</h2>
          <div class="cb_row b_25">
            <div class="cb_item long">
              <div class="cbi_name">Юридический адрес <span>*</span></div>
              <input v-model="address" class="cbi_input" type="text" required="required" :class="error.includes('companyAddress') ? 'cbi_input--error' : ''">
            </div>
            <div class="cb_item">
              <div class="cbi_name">Общая почта <span>*</span></div>
              <input v-model="email" class="cbi_input email" type="email" placeholder="example@mail.ru" required="required" :class="error.includes('companyEmail') ? 'cbi_input--error' : ''">
            </div>
            <div class="cb_item">
              <div class="cbi_name">Общий телефон <span>*</span></div>
              <input v-model="phone" class="cbi_input phone" type="tel" placeholder="+7(___)___-__-__" :class="error.includes('companyPhone') ? 'cbi_input--error' : ''"  v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" required="required">
            </div>
          </div>
          <div class="cb_warning">Проверьте, что у вас есть доступ к почте. На эту почту будет направлена ссылка на создание пароля.</div>
        </div>

        <div class="c_block">
          <h2 class="scroll_user">Контактное лицо</h2>
          <div class="cb_row">
            <div class="cb_item long">
              <div class="cbi_name">ФИО <span>*</span></div>
              <input v-model="contact_fio" class="cbi_input" type="text" required="required" :class="error.includes('contact_fio') ? 'cbi_input--error' : ''">
            </div>
            <div class="cb_item long">
              <div class="cbi_name">Должность</div>
              <input v-model="contact_work" class="cbi_input" type="text" required="required" :class="error.includes('contact_work') ? 'cbi_input--error' : ''">
            </div>
          </div>
          <div class="cb_row b_25_l">
            <div class="cb_item">
              <div class="cbi_name">Телефон <span>*</span></div>
              <input v-model="contact_phone" class="cbi_input phone" :class="error.includes('contact_phone') ? 'cbi_input--error' : ''" type="tel" placeholder="+7(___)___-__-__" v-mask="'+7(###)###-##-##'" @blur="maskResetPhone" required="required">
            </div>
            <div class="cb_item">
              <div class="cbi_name">Почта <span>*</span></div>
              <input v-model="contact_email" class="cbi_input email" type="email" placeholder="example@mail.ru" required="required" :class="error.includes('contact_email') ? 'cbi_input--error' : ''">
            </div>
          </div>
        </div>
      </template>



        <div class="kp_bot">
            <a class="btn kp_next" @click="nextStep">Далее</a>
            <a data-modal="modal" data-pop="#cancel" class="btn_3" @click="modalCancel1 = true">Направить отказ от подачи КП</a>
        </div>
    </div>

    <div class="step" v-if="step2">
        <div class="stages">
            <div class="stage passed">
                <span>1</span>
                <div>Выбор позиций</div>
            </div>
            <div class="stage">
                <span>2</span>
                <div>Подача ценового предложения</div>
            </div>
        </div>

        <a class="btn_3 back_btn" @click="prevStep">Вернуться к выбору позиций</a>

        <div class="c_block">
            <h2 class="no_bd scroll_offer">Предложение</h2>
            <div class="cb_warning_2 ">Заполните один из столбцов Цена без НДС или Цена с НДС. Заполните столбец Срок производства. Если товар является аналогом, отметьте это и напишите комментарий</div>
            <div class="pc_scroll2">
                <div class="pos_table_2 pos_table">
                    <div class="pot_line">
                        <div>Номенклатура</div>
                        <div>Кол-во</div>
                        <div>ЕИ</div>
                        <div>Предельно допустимая цена без НДС</div>
                        <div>Цена без НДС</div>
                        <div>Цена с НДС</div>
                        <div>Сумма с НДС</div>
                        <div>Срок производства, календарных дней</div>
                        <div>Кратность</div>
                        <div>Аналог</div>
                        <div>Комментарий</div>
                    </div>
                    <div class="pot_line" v-show="item.active" v-for="(item, key) in positions">
                        <div>{{ item.name }}</div>
                        <div>{{ item.quantity }}</div>
                        <div>{{ item.unit }}</div>
                        <div>{{ item.maxPrice }} <span>₽/</span><span>{{ item.unit }}</span></div>
                        <div><input class="pl_input th" :class="error.includes('position_'+key+'_prices') ? 'pl_input--error' : ''" type="text" :placeholder="'₽/'+item.unit" v-model="item.noTaxPrice" @change="pricecalc(key, 'price')" @keyup="pricecalc(key, 'price')"></div>
                        <div><div><input class="pl_input th" :class="error.includes('position_'+key+'_prices') ? 'pl_input--error' : ''" type="text" :placeholder="'₽/'+item.unit" v-model="item.taxPrice" @change="pricecalc(key, 'taxprice')" @keyup="pricecalc(key, 'taxprice')"></div></div>
                        <div><div><input class="pl_input th" type="text" :placeholder="'₽/'+item.unit" v-model="item.sum" @change="pricecalc(key, 'sum')" @keyup="pricecalc(key, 'sum')"></div></div>
                        <div><input class="pl_input" :class="error.includes('position_'+key+'_term') ? 'pl_input--error' : ''" type="text"  @change="termcalc(key)" @keyup="termcalc(key)" v-model="item.term"></div>
                        <div><input class="pl_input" type="text" v-model="item.multiplicity"></div>
                        <div><label class="pl_check niceCheck">
                            <input type="checkbox" v-model="item.analogue">
                            <span>Да</span>
                        </label></div>
                        <div><input class="pl_input" :class="error.includes('position_'+key+'_comment') ? 'pl_input--error' : ''" type="text" :placeholder="'Комментарий'+(item.analogue?'*':'')" v-model="item.comment"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2 class="scroll_pay">Условия оплаты</h2>
            <div class="pay_checks">
                <div class="pc_item">
                    <label class="niceCheck">
                        <input type="checkbox" value="prePay" v-model="payment">
                        <span>Предоплата</span>
                    </label>
                  <div class="cb_items" v-show="payment.indexOf('prePay') != -1">
                    <div class="cb_item">
                      <div v-if="false" class="cbi_name">Процент <span>*</span></div>
                      <input placeholder="Процент *" class="cbi_input" :class="error.includes('payment_percent') ? 'cbi_input--error' : ''" type="text" v-model="payment_percent['prePay']">
                    </div>
                  </div>
                </div>
                <div class="pc_item">
                    <label class="niceCheck">
                        <input type="checkbox" value="preShip" v-model="payment">
                        <span>Оплата перед отгрузкой</span>
                    </label>
                    <div class="cb_items" v-show="payment.indexOf('preShip') != -1">
                      <div class="cb_item">
                        <div v-if="false" class="cbi_name">Процент <span>*</span></div>
                        <input placeholder="Процент *" class="cbi_input" :class="error.includes('payment_percent') ? 'cbi_input--error' : ''" type="text" v-model="payment_percent['preShip']">
                      </div>
                    </div>
                </div>
                <div class="pc_item">
                    <label class="niceCheck">
                        <input type="checkbox" value="afterShip" v-model="payment">
                        <span>Оплата после отгрузки</span>
                    </label>
                    <div class="cb_items" v-show="payment.indexOf('afterShip') != -1">
                        <div class="cb_item">
                            <div v-if="false" class="cbi_name">Процент <span>*</span></div>
                            <input placeholder="Процент *" class="cbi_input" :class="error.includes('payment_percent') ? 'cbi_input--error' : ''" type="text" v-model="payment_percent['afterShip']">
                        </div>
                    </div>
                </div>
                <div class="pc_item">
                    <label class="niceCheck">
                        <input type="checkbox" value="delay" v-model="payment">
                        <span>Отсрочка</span>
                    </label>
                    <div class="cb_items" v-show="payment.indexOf('delay') != -1">
                        <div class="cb_item">
                            <div v-if="false" class="cbi_name">Процент <span>*</span></div>
                            <input placeholder="Процент *" class="cbi_input" :class="error.includes('payment_percent') ? 'cbi_input--error' : ''" type="text" v-model="payment_percent['delay']">
                        </div>
                        <div class="cb_item">
                            <div v-if="false" class="cbi_name">Дней <span>*</span></div>
                            <input placeholder="Дней *" class="cbi_input" :class="error.includes('delayDays') ? 'cbi_input--error' : ''" type="text" v-model="payment_days['delay']">
                        </div>
                    </div>
                </div>
            </div>
            <div class="cb_warning" ref="paymentAlert">Сумма должна составлять 100%</div>
        </div>

        <div class="c_block">
            <h2 class="scroll_dop">Дополнительная информация</h2>
            <div class="cb_row">
                <div v-if="is_auth" class="cb_item long" ref="store">
                    <div class="cbi_name">Склад <span>*</span></div>
                    <select class="selectBlock" v-model="storeTax">
                        <option value="new">Новый</option>
                        <option v-for="item in profile.sklad" :value="item.address">{{ item.address }}</option>
                    </select>
                </div>
                <div class="cb_item long" v-if="storeTax=='new'">
                    <div class="cbi_name">Адрес склада <span>*</span></div>
                    <input class="cbi_input" :class="error.includes('storeAddress') ? 'cbi_input--error' : ''" type="text" placeholder="Спб, Шамшева улица, 13" v-model="storeAddress">
                </div>
            </div>
            <div class="cb_row">
                <div class="hf_item">
                    <div class="hfi_name">Срок действия КП <span>*</span></div>
                    <div class="hfi_date">
                        <input class="cbi_input" :class="error.includes('kpDate') ? 'cbi_input--error' : ''" type="text" id="datepicker" placeholder="__.__.____" v-mask="'##.##.####'" v-model="kpDate" @blur="maskResetDate">
                    </div>
                </div>
            </div>
        </div>

        <div class="c_block">
            <h2>Вложения</h2>
            <div class="cb_warning" ref="filesAlert">Прикрепите документ</div>
            <ol class="attach_list">
                <li>Во вложения просим Вас добавить файл коммерческого предложения с указанием следующих данных:
                    <ol type="a">
                        <li>Цена за единицу с учетом скидки при условии самовывоза со склада</li>
                        <li>Сроки производства</li>
                        <li>Условия оплаты</li>
                        <li>Сроки действия КП</li>
                        <li>Адрес склада</li>
                    </ol>
                </li>
                <li>В случае, если вы являетесь официальным дилером обращаемся с просьбой приложить сертификат.</li>
            </ol>
            <div class="file-name-field" id="file_name" ref="fileName"></div>
            <div class="file-upload">
                <label>
                    <input type="file" @change="processFiles" id="uploaded-file" multiple>
                    <span>Прикрепить файлы</span>
                </label>
            </div>
        </div>
        <div class="kp_bot_2 scroll_agree">
            <label class="conf_check niceCheck" :class="error.includes('agreementOne') ? 'conf_check--error' : ''">
                <input type="checkbox" v-model="agreementOne">
                <span>Внесенные цены можно считать юридической офертой</span>
            </label>
            <br>
            <label class="conf_check niceCheck" :class="error.includes('agreementTwo') ? 'conf_check--error' : ''">
                <input type="checkbox" v-model="agreementTwo">
                <span>Я соглашаюсь с <router-link to="/page/policy">Политикой конфиденциальности</router-link> и <router-link to="/page/rule">Правилами сайта</router-link></span>
            </label>

            <div class="cb_warning">После нажатия на кнопку «Сформировать КП», отредактировать предложение можно будет только по телефону или почте</div>

            <button data-modal="modal" data-pop="#kp_accepted_log" class="create_kp btn" @click="send">Сформировать КП</button>
        </div>
    </div>

    <div class="boxes" v-if="modalSuccess || modalCancel1 || modalCancel2">
        <div id="cancel" class="window" v-if="modalCancel1 || modalCancel2">
            <span class="close" @click="closeAllModals"></span>

            <div class="m_content">
                <div class="mc_block" v-show="modalCancel1">
                    <h2 class="m_head">Вы точно хотите направить отказ от подачи ценового предложения?</h2>
                    <div class="m_btns">
                        <a class="send_cancel btn_2" @click="cancelKP">Да</a>
                        <a class="close_btn btn" @click="closeAllModals">Нет</a>
                    </div>
                </div>
                <div class="mc_send" v-show="modalCancel2">
                    <h2 class="m_head">{{error_text?error_text:'Отказ от подачи ценового предложения принят.'}}</h2>
                    <div class="m_btns">
                        <a class="close_btn btn" @click="closeAllModals">Ок</a>
                    </div>
                </div>
            </div>
        </div>

        <div id="kp_accepted" class="window" v-if="modalSuccess">
            <span class="close" @click="closeAllModals"></span>
            <div class="m_content">
                <div class="mc_block">
                    <h2 class="m_head">Спасибо! Ваше коммерческое предложение принято.</h2>
                    <div class="m_text" v-if="!authenticated">В течение 3-5 дней на вашу почту будет выслана ссылка для формирования пароля от Личного кабинета</div>
                    <div class="m_text">Изменения принимаются по телефону <a href="tel:+79000000000">+7 (900) 000-00-00</a> или почте <a href="mailto:supply@info.ru" class="h_email">supply@info.ru</a></div>
                    <div class="m_text">Мы свяжемся с вами, если потребуется уточнить информацию</div>
                    <div class="m_btns">
                        <a data-modal="modal" data-pop="#kp_accepted" class="btn" @click="closeAllModals">Ок</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="mask" @click="closeAllModals"></div>
    </div>
  </template>
</template>

<script>
import Choices from 'choices.js';
import {mask as maskDirective } from 'vue-the-mask';
import jQuery from 'jquery';
import '@/js/jquery-ui.min';
import '@/css/jquery-ui.css';
import axios from "axios";
// import jqueryMousewheel from '@/js/jquery.mousewheel.min';
// import mCustomScrollbar from '@/js/mCustomScrollbar.min';
// import '@/css/mCustomScrollbar.min.css';

export default {
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    data: () => ({
        authenticated: true,
        step1: true,
        step2: false,
        modalSuccess: false,
        modalCancel1: false,
        modalCancel2: false,
        error: [],

        toggleAllPositions: false,

        tax: '',
        positions: {},
      positions_orig: {},
        payment: [],
        payment_percent: {},
        payment_days: {
          delay: ''
        },
        storeTax: 'new',
        storeAddress: '',
        kpDate: '',
        files: null,
        agreementOne: false,
        agreementTwo: false,
        request: {},
      contact: '',
      is_send: false,
      error_text: '',
      inn: '',
      kpp: '',
      name: '',
      nds: 0,
      email: '',
      address: '',
      phone: '',
      contact_fio: '',
      contact_work: '',
      contact_phone: '',
      contact_email: '',
      is_auth: false,
      request_error: ''
    }),
    computed: {
        profile() {
          if (this.$store.getters.profile)
            return this.$store.getters.profile;
          else
            return {};
      }
    },
    async mounted() {
        this.createSelects();

        this.activateDatepicker();

        this.get();

        await this.$store.dispatch('fetchProfile');
        // jQuery('.pot_scroll, .pc_scroll').mCustomScrollbar({
        //     axis: 'x',
        // });
    },
    updated() {
        this.createSelects();

        this.activateDatepicker();


    },
    components: {

    },
    methods: {
        async get() {
          let _this = this;
          await axios.get('/local/api/requestproducts/'+this.$route.meta.type+'/'+this.$route.params.id).then(function (response) {
            if (!response.data.request || response.data.request.length==0) {
              if (response.data.error) {
                _this.request_error = response.data.error;
                return;
              }
              else
                _this.$router.push('/404');
            }
            else {
              _this.positions = response.data.positions;
              _this.positions_orig = response.data.positions;
              _this.request = response.data.request;
              _this.email = response.data.request.email;
              _this.inn = response.data.request.inn;
              _this.kpp = response.data.request.kpp;
              _this.is_auth = response.data.is_auth;
            }

          }).catch(function(err) {

          });
        },
      termcalc(key) {
        this.positions[key].term = parseInt(this.positions[key].term);
        if (Number.isNaN(this.positions[key].term)) {
          this.positions[key].term = '';
          return;
        }
        if (parseInt(this.positions[key].term) < 0)
          this.positions[key].term = parseInt(this.positions[key].term)*-1;
      },
      pricecalc:function(key, type) {
        let nds = '';
        if (this.is_auth)
          nds = this.profile.nds;
        else
          nds = this.nds;
        let k = 1;
        if (nds == '10%')
          k = 1.1;
        else if (nds == '20%')
          k = 1.2;

        if (type == 'price') {
          this.positions[key].noTaxPrice = this.positions[key].noTaxPrice.replace(",", ".");
          console.log(this.positions[key].noTaxPrice, Number.isNaN(this.positions[key].noTaxPrice))
          if (parseFloat(this.positions[key].noTaxPrice) < 0)
            this.positions[key].noTaxPrice = parseFloat(this.positions[key].noTaxPrice)*-1;

          if (Number.isNaN(parseFloat(this.positions[key].noTaxPrice))) {
            this.positions[key].noTaxPrice = '';
            this.positions[key].taxPrice = '';
            this.positions[key].sum = '';
            return;
          }

          this.positions[key].taxPrice = parseFloat(this.positions[key].noTaxPrice) * k;
          this.positions[key].sum = parseFloat(this.positions[key].quantity) * parseFloat(this.positions[key].taxPrice);

          this.positions[key].taxPrice = this.round(this.positions[key].taxPrice);
          this.positions[key].sum = this.round(this.positions[key].sum);
        }
        else if (type == 'taxprice') {
          this.positions[key].taxPrice = this.positions[key].taxPrice.replace(",", ".");
          if (parseFloat(this.positions[key].taxPrice) < 0)
            this.positions[key].taxPrice = parseFloat(this.positions[key].taxPrice)*-1;
          if (Number.isNaN(parseFloat(this.positions[key].taxPrice))) {
            this.positions[key].noTaxPrice = '';
            this.positions[key].taxPrice = '';
            this.positions[key].sum = '';
            return;
          }

          this.positions[key].noTaxPrice = parseFloat(this.positions[key].taxPrice) / k;
          this.positions[key].sum = parseFloat(this.positions[key].quantity) * parseFloat(this.positions[key].taxPrice);

          this.positions[key].noTaxPrice = this.round(this.positions[key].noTaxPrice);
          this.positions[key].sum = this.round(this.positions[key].sum);
        }
        else if (type == 'sum') {
          this.positions[key].sum = this.positions[key].sum.replace(",", ".");
          if (parseFloat(this.positions[key].sum) < 0)
            this.positions[key].sum = parseFloat(this.positions[key].sum)*-1;
          if (Number.isNaN(parseFloat(this.positions[key].sum))) {
            this.positions[key].noTaxPrice = '';
            this.positions[key].taxPrice = '';
            this.positions[key].sum = '';
            return;
          }

          this.positions[key].taxPrice = parseFloat(this.positions[key].sum) / parseFloat(this.positions[key].quantity);
          this.positions[key].noTaxPrice = parseFloat(this.positions[key].taxPrice) / k;

          this.positions[key].taxPrice = this.round(this.positions[key].taxPrice);
          this.positions[key].noTaxPrice = this.round(this.positions[key].noTaxPrice);
        }


      },
      round(num) {
          let number = Math.round((num + Number.EPSILON) * 100) / 100;
        if (num - number == 0)
          return num
        else
          return number;
      },
        activateDatepicker: function() {
            jQuery.datepicker.regional['ru'] = {
                closeText: 'Закрыть',
                prevText: 'Предыдущий',
                nextText: 'Следующий',
                currentText: 'Сегодня',
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
                dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
                dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                weekHeader: 'Не',
                dateFormat: 'dd.mm.yy',
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: '',
                minDate: new Date()
            };
            jQuery.datepicker.setDefaults(jQuery.datepicker.regional['ru']);

            let _this = this;
            jQuery('#datepicker').datepicker({
                showOn: "button",
                buttonImage: "/front/dist/images/calendar.svg",
                buttonImageOnly: true,
                dateFormat: "dd.mm.yy",
                buttonText: "Выберите дату",
                onSelect: function(selectedDate, datePicker) {
                    _this.kpDate = selectedDate;
                }
            });
        },
        createSelects: function() {
            let _this = this,
                selectList = document.querySelectorAll('.selectBlock');

            selectList.forEach(function (item) {
                let choices = new Choices(item, {
                    placeholder: true,
                    position: 'bottom',
                });

                item.addEventListener('change', (event) => {
                    item.closest('.choices').querySelector('.choices__inner').classList.remove('choices__inner--error');
                    _this.tax = event.target.value;
                });
            });
        },
        scrollToTop: function() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        nextStep: function() {
            let errorTax = false,
                errorPositions = true,
                top = 0;
          this.error = [];

            if (this.tax == '') {
                errorTax = true;
            }

            for (let i in this.positions) {
                if (this.positions[i].active) {
                    errorPositions = false;
                }
            };



            if (!this.is_auth) {

              if (this.contact_fio == '') {
                this.error.push('contact_fio');
                top = document.querySelector(".scroll_user").offsetTop;
              }

              if (this.contact_email == '') {
                this.error.push('contact_email');
                top = document.querySelector(".scroll_user").offsetTop;
              }

              if (this.contact_phone == '' || this.contact_phone < 16) {
                this.error.push('contact_phone');
                top = document.querySelector(".scroll_user").offsetTop;
              }

              if (this.address == '') {
                this.error.push('companyAddress');
                top = document.querySelector(".scroll_contacts").offsetTop;
              }

              if (this.email == '') {
                this.error.push('companyEmail');
                top = document.querySelector(".scroll_contacts").offsetTop;
              }

              if (this.phone == '' || this.phone < 16) {
                this.error.push('companyPhone');
                top = document.querySelector(".scroll_contacts").offsetTop;
              }

              if (this.name == '') {
                this.error.push('companyName');
                top = document.querySelector(".scroll_org").offsetTop;
              }

              if (this.inn == '') {
                this.error.push('companyInn');
                top = document.querySelector(".scroll_org").offsetTop;
              }

              if (this.kpp && this.kpp.length < 9) {
                this.error.push('companyKpp');
                top = document.querySelector(".scroll_org").offsetTop;
              }

              if (!this.nds) {
                this.error.push('companyTax');
                top = document.querySelector(".scroll_org").offsetTop;
              }
            }
            else {
              if (errorTax) {
                this.$refs.taxSelect.querySelector('.choices__inner').classList.add('choices__inner--error');
                this.error.push('taxSelect');
                top = document.querySelector(".scroll_contacts").offsetTop;
              }
              else
                this.$refs.taxSelect.querySelector('.choices__inner').classList.remove('choices__inner--error');
            }
              if (errorPositions) {
                this.$refs.positionsAlert.classList.add('cb_warning--error');
                this.error.push('positions');
                top = document.querySelector(".scroll_positions").offsetTop;

              }


            if(this.error.length == 0) {


                this.step1 = false;
                this.step2 = true;

                this.scrollToTop();
            }
            else
              window.scrollTo(0, top);
        },
        prevStep: function() {
            this.step1 = true;
            this.step2 = false;

            this.scrollToTop();
        },
        toggleAllCheckboxes: function() {
            this.toggleAllPositions = !this.toggleAllPositions;
            for (let i in this.positions) {
                this.positions[i].active = this.toggleAllPositions;
            }

            this.$refs.positionsAlert.classList.remove('cb_warning--error');
        },
        removePositionsError: function() {
            this.$refs.positionsAlert.classList.remove('cb_warning--error');
        },
        closeAllModals: function() {
            this.modalSuccess = false;
            this.modalCancel1 = false;
            this.modalCancel2 = false;
            if (this.is_send == true)
              this.$router.push("/history");
        },

        processFiles: function(e) {
            this.files = this.files || [];
            let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

            for (let file of files) {
                let fileText = file.name.replace(/\\/g, '/').split('/').pop();
                this.$refs.fileName.insertAdjacentHTML('beforeend', '<span>Имя файла: ' + fileText + '</span>')

                //file.objectURL = window.URL.createObjectURL(file);
                this.files.push(file);
            }

            // this.file = file;
            // file = file.replace(/\\/g, '/').split('/').pop();
            // this.$refs.fileName.innerText = 'Имя файла: ' + file;
        },
        maskResetDate: function(e) {
            if (e.target.value.length < 10)
                e.target.value = '';
        },
        send: function(e) {
            let form = e.target,
                formData = new FormData(),
                _this = this;
            let top = 0;

            this.error = [];

            if (_this.storeTax != "new")
              _this.storeAddress = _this.storeTax;

            let sum_percent = 0;
          let ii;
            for (ii in _this.payment_percent) {
              debugger;

              if (_this.payment.indexOf(ii) != -1)
              sum_percent += parseInt(_this.payment_percent[ii]);
              console.log(_this.payment.indexOf(ii), ii, parseInt(_this.payment_percent[ii]), sum_percent)
            }
            console.log(sum_percent)


          formData.append('request_id', _this.$route.params.id);
          formData.append('type', _this.$route.meta.type);
          formData.append('contact', _this.contact);
            formData.append('tax', _this.tax); //+
            formData.append('positions', JSON.stringify(_this.positions));
            formData.append('payment', JSON.stringify(_this.payment));
          formData.append('payment_percent', JSON.stringify(_this.payment_percent));
          formData.append('payment_days', _this.payment_days['delay']);
            formData.append('storeTax', _this.storeTax); //+
            formData.append('storeAddress', _this.storeAddress); //+
            formData.append('kpDate', _this.kpDate); //+


          if (!this.is_auth) {
            formData.append('name', this.name);
            formData.append('inn', this.inn);
            formData.append('kpp', this.kpp);
            formData.append('nds', this.nds);
            formData.append('address', this.address);
            formData.append('email', this.email);
            formData.append('phone', this.phone);
            formData.append('contact_fio', this.contact_fio);
            formData.append('contact_work', this.contact_work);
            formData.append('contact_phone', this.contact_phone);
            formData.append('contact_email', this.contact_email);
          }
          else
            formData.append('contact', this.contact);
let i;
          for( i in this.files){
            let file = this.files[i];
            if (file.error) {
              this.error.push('files');
            }
            formData.append('files[' + i + ']', file);
          }

          if (!_this.agreementOne) {
            this.error.push('agreementOne');
            top = document.querySelector(".scroll_agree").offsetTop;
          }

          if (!_this.agreementTwo) {
            this.error.push('agreementTwo');
            top = document.querySelector(".scroll_agree").offsetTop;
          }

          //error scroll_dop
            if (formData.get('storeTax') == '') {
                this.error.push('storeTax');
                this.$refs.store.querySelector('.choices__inner').classList.add('choices__inner--error');
              top = document.querySelector(".scroll_dop").offsetTop;
            }

            if (formData.get('storeAddress') == '') {
                this.error.push('storeAddress');
              top = document.querySelector(".scroll_dop").offsetTop;
            }

            if (formData.get('kpDate') == '' || formData.get('kpDate').length < 10) {
                this.error.push('kpDate');
              top = document.querySelector(".scroll_dop").offsetTop;
            }


          //error scroll_pay

          if (sum_percent != 100) {
            this.error.push('payment_percent');
            top = document.querySelector(".scroll_pay").offsetTop;
            this.$refs.paymentAlert.classList.add('cb_warning--error');
          }
          else
            this.$refs.paymentAlert.classList.remove('cb_warning--error');

          if (_this.payment.indexOf('delay') != -1 && _this.payment_days['delay'] == '') {
            this.error.push('delayDays');
            top = document.querySelector(".scroll_pay").offsetTop;
          }

          if (_this.payment.length == 0) {
            this.error.push('payment');
            this.$refs.paymentAlert.classList.add('cb_warning--error');
            top = document.querySelector(".scroll_pay").offsetTop;
          } else if (sum_percent == 100) {
            this.$refs.paymentAlert.classList.remove('cb_warning--error');
          }

          // error scroll_offer
            let positionsArray = JSON.parse(formData.get('positions'));
            for (let key in positionsArray) {
                if (positionsArray[key].active == true) {
                    if (positionsArray[key].noTaxPrice == '' && positionsArray[key].taxPrice == '') {
                        this.error.push('position_'+key+'_prices');
                        top = document.querySelector(".scroll_offer").offsetTop;
                    }

                    if (positionsArray[key].term == '') {
                        this.error.push('position_'+key+'_term');


                      top = document.querySelector(".scroll_offer").offsetTop;
                    }
                  if (positionsArray[key].analogue && positionsArray[key].comment == '') {
                    this.error.push('position_'+key+'_comment');
                    top = document.querySelector(".scroll_offer").offsetTop;
                  }
                }
            }

            console.log(_this.error)
          if (_this.error.length == 0) {
          axios.post('/local/api/kp/add', formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }}).then(response => {
            _this.error_text = '';
            let data = response.data;
            _this.is_send = true;

            if (data.status === 'ok') {
              _this.modalSuccess = true;
              _this.step1 = true;
              _this.step2 = false;

              _this.payment = '';
              _this.payment_percent = '';
              _this.payment_days = '';
              _this.storeTax = 'new';
              _this.storeAddress = '';
              _this.kpDate = '';
              _this.files = null;
              _this.agreementOne = false;
              _this.agreementTwo = false;
              _this.positions = _this.positions_orig;
              _this.contact = '';
            }
            else {
              //_this.error.push('login')
              _this.error_text = data.text;
              _this.modalCancel1 = false;
              _this.modalCancel2 = true;
              console.log(data)
            }
          });

            }
          else {
            debugger;
            window.scrollTo(0, top);
          }
        },
      cancelKP: function () {
          let _this = this;
          this.error_text = '';
        axios.post('/local/api/kp/cancel', {
          id: this.$route.params.id
        },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }}).then(response => {
          _this.error_text = '';
          let data = response.data;

          if (data.status === 'ok') {
            _this.modalCancel1 = false;
            _this.modalCancel2 = true;
            _this.is_send = true;
          }
          else {
            //_this.error.push('login')
            _this.error_text = data.text;
            _this.modalCancel1 = false;
            _this.modalCancel2 = true;
            console.log(data)
          }
        });
      },
      maskResetPhone: function(e) {
        if (e.target.value.length < 16)
          e.target.value = '';
      },
      maskResetKpp: function(e) {
        if (e.target.value.length < 9)
          e.target.value = '';
      },
      maskResetInn: function(e) {
        if (e.target.value.length != 10 && e.target.value.length != 12)
          e.target.value = '';
      },
    },
}
</script>

