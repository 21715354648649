<template>
    <h1>История КП</h1>

    <div class="h_table">
        <form class="h_filter" action="#" @submit.prevent="send">
            <div class="hf_left">
                <div class="hf_item">
                    <div class="hfi_name">Номер закупки</div>
                    <input type="text" class="hfi_input" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                           v-model="number">
                </div>
                <div class="hf_item">
                    <div class="hfi_name">Дата отправки КП</div>
                    <div class="hfi_date"><input type="text" id="datepicker" placeholder="__.__.____" v-model="date"
                                                 v-mask="'##.##.####'" @blur="maskResetDate"></div>
                </div>
              <div class="hf_item" v-if="false">
              <div class="hfi_name">Дата отправки КП</div>
              <div class="hfi_date"><Datepicker v-model="date" :clearable="false" language="ru" :locale="ru" inputFormat="dd.MM.yyyy" :enableTimePicker="false" class="personal-input input-date" placeholder="__.__.____">
              </Datepicker></div>
            </div>
                <div class="hf_item">
                    <div class="hfi_name">Контактное лицо</div>
                    <select class="selectBlock" v-model="contact">
                    </select>
                </div>
            </div>
            <div class="hf_right">
                <label class="hf_check niceCheck">
                    <input type="checkbox" name="ch1" v-model="sent">
                    <span>Направлены</span>
                </label>
                <label class="hf_check niceCheck">
                    <input type="checkbox" name="ch1" v-model="rejected">
                    <span>Отказ</span>
                </label>
                <label class="hf_check niceCheck">
                    <input type="checkbox" name="ch1" v-model="autoRejected">
                    <span>Автоотказ</span>
                </label>
                <button class="hf_btn btn">Применить</button>
            </div>
            <div class="ht_close" @click="closefilter"></div>
        </form>
        <div class="pt_sort_btn btn_3" @click="opensort">Параметры сортировки</div>
        <div class="ht_filter_btn btn_3" @click="openfilter">Параметры фильтрации</div>
        <div class="h_block">
            <div class="ht_line_top">
                <span :class="{'active': sortby=='number', 'revert':order.number==1}" @click="sort('number')">Номер закупки</span>
                <span :class="{'active': sortby=='positions', 'revert':order.positions==1}" @click="sort('positions')">Запрошено позиций</span>
                <span :class="{'active': sortby=='offer', 'revert':order.offer==1}" @click="sort('offer')">Предложено позиций</span>
                <span :class="{'active': sortby=='date', 'revert':order.date==1}" @click="sort('date')">Дата  КП</span>
                <span :class="{'active': sortby=='kp', 'revert':order.kp==1}" @click="sort('kp')">КП</span>
                <span :class="{'active': sortby=='contact', 'revert':order.contact==1}" @click="sort('contact')">Контактное лицо</span>
                <div class="pt_close" @click="closesort"></div>
            </div>
            <template v-for="item in items">

                <router-link v-if="!item.cancel" :to="'/history/kp/'+item.id" class="ht_line" :class="{history_cancel: item.cancel}">
                    <span>{{ item.number }}</span>
                    <span>{{ item.position_request }}</span>
                    <span>{{ item.position_response }}</span>
                    <span>{{ item.date }}</span>
                    <span>{{ item.status }}</span>
                    <span>{{ item.contact }}</span>
                </router-link>
                <div v-if="item.cancel" class="ht_line" :class="{history_cancel: item.cancel}">
                  <span>{{ item.number }}</span>
                  <span>{{ item.position_request }}</span>
                  <span>{{ item.position_response }}</span>
                  <span>{{ item.date }}</span>
                  <span>{{ item.status }}</span>
                  <span>{{ item.contact }}</span>
                </div>
            </template>
        </div>
    </div>

    <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>
</template>

<script>
import Choices from 'choices.js';
import {mask as maskDirective} from 'vue-the-mask';
import jQuery from 'jquery';
import '@/js/jquery-ui.min';
import '@/css/jquery-ui.css';
import axios from "axios";
import Pagination from "@/components/app/Pagination"
import Datepicker from 'vue3-datepicker'
import {ru} from 'date-fns/locale'
window['ru'] = ru;
// import '@/js/selectbox.js';

export default {
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    components: {Pagination, Datepicker},
    data: () => ({
      order: {
        number: 1,
        positions: 1,
        date: 0,
        offer: 1,
        kp: 1,
        contact: 1
      },
      sortby: 'date',
      page: 1,
        number: '',
        date: '',
        contact: '0',
        sent: false,
        rejected: false,
        autoRejected: false,
        items: {},
        pagination: {},
        contacts: false,
        choices: {}
    }),
    async mounted() {
        this.gotopage(1);

        this.createSelects();

        jQuery.datepicker.regional['ru'] = {
            closeText: 'Закрыть',
            prevText: 'Предыдущий',
            nextText: 'Следующий',
            currentText: 'Сегодня',
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekHeader: 'Не',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: '',
            changeMonth: true,
            changeYear: true
        };
        jQuery.datepicker.setDefaults(jQuery.datepicker.regional['ru']);

        let _this = this;
        jQuery('#datepicker').datepicker({
            showOn: "button",
            buttonImage: "/front/dist/images/calendar.svg",
            buttonImageOnly: true,
            dateFormat: "dd.mm.yy",
            buttonText: "Выберите дату",
            onSelect: function (selectedDate, datePicker) {
                _this.date = selectedDate;
            }
        });
    },
    computed: {
        page() {
            return this.pagination;
        },
      ru() {
        return window['ru'];
      }
    },
    methods: {
        async gotopage(page) {
            let _this = this;
            let filter = this.getFilter();
            await axios.post('/local/api/history?page=' + page+'&sort='+this.order[this.sortby]+'&sortby='+this.sortby, filter).then(function (response) {
              _this.page = page;
                _this.items = response.data.items;
                _this.pagination = response.data.pagination;
                if (!_this.contacts) {
                    _this.contacts = response.data.contacts;
                    _this.choices.setValue(_this.contacts);
                  _this.choices.setChoiceByValue('Выберите');
                }
            }).catch(function (err) {
                _this.$router.push('/404');
            });
        },
        createSelects: function () {
            let item = document.querySelector('.selectBlock');
            //selectList.forEach(function (item) {
            this.choices = new Choices(item, {
                placeholder: true,
                position: 'bottom',
              shouldSort: false
            });

            // });
        },
        getFilter: function () {
            let formData = new FormData(),
                _this = this;
            formData.append('number', _this.number);
            formData.append('date', _this.date);
            formData.append('contact', _this.contact);
            formData.append('sent', _this.sent);
            formData.append('rejected', _this.rejected);
            formData.append('autoRejected', _this.autoRejected);
            return formData;
        },
        send: function (e) {
            this.gotopage(1);
        },
        maskResetDate: function (e) {
            if (e.target.value.length < 10)
                e.target.value = '';
        },
        opensort() {
          jQuery(".ht_line_top").slideDown();
        },
        closesort() {
          jQuery('.ht_line_top').slideUp(200);
        },
        openfilter() {
          jQuery(".h_filter").slideDown();
        },
        closefilter() {
          jQuery('.h_filter').slideUp(200);
        },
      sort(field) {
          debugger;

        if (this.order[field]==1) {
          this.order[field]=0;
        }
        else {
          this.order[field] = 1;
        }
        this.sortby = field;
        this.gotopage(this.page)
      },
    },
}
</script>

