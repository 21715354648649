<template>
    <h1 class="ab_head">Ошибка</h1>
    <div class="p_404">404</div>
    <h2 class="ab_text_2">Страница не найдена</h2>
    <router-link v-if="authenticated" to="/" class="btn">Запросы на расценку</router-link>
  <router-link v-if="!authenticated" to="/auth" class="btn">Вход в ЛК</router-link>
</template>

<script>
export default {
    mounted() {
        //this.$store.dispatch('Loader');
    },
    components: {},
    methods: {},
    computed: {
      authenticated() {
        return this.$store.state.isAuth
      },
    }
}
</script>

